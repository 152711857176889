import React from "react"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleRight 
} from "@fortawesome/pro-regular-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button, Alert } from "react-bootstrap"

import PageTitle from "../components/PageTitle/PageTitle"
import Partners from "../components/PartnersSponsors/Partners"
import Sponsors from "../components/PartnersSponsors/Sponsors"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import MadshusLogo from "../images/madshus-logo"

const CovidAlert = () => (
  <Alert variant="danger">
        <Alert.Heading style={{fontSize: '2rem'}}>COVID-19 Changes</Alert.Heading>
        <p><strong>INTERVAL STARTS!</strong> The 24, 14, and 7 km races will be intervals starts separated by 20 seconds. There will be a 3-minute gap between each of the distance races. Individual start times will be emailed to registered participants on Friday before the race.</p>
        <p><strong>LUNCH and AWARDS:</strong> This year we will not be having a post-race lunch and awards ceremony due to Covid precautions. The price of the event has been lowered to reflect this.  Results will be posted online and at the start/finish area as they become available on race day.</p>
        <p><strong>AID STATIONS:</strong> There will be 2 self-serve aid stations out on the course with small individual bottled water and individual packets of energy gel/Hammer Gel.  There will be no handouts of drink or gels at the aid stations. Aid stations will have volunteers re-suppling drinks and gels on the aid station tables. </p>
      </Alert>
)

const options = {
  renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({data: { target: { fields }}}) => 
          <div dangerouslySetInnerHTML={{__html: `<img src="${fields.file['en-US'].url}" alt="${fields.title['en-US']}" class="fluid" style="width: 100%; margin: 20px 0 20px 0" />` }} />
  }
}

const IndexPage = ({ data }) => {
  
  const pageContent = data.allContentfulInfoPage.edges[0].node.pageSection;
  console.log(pageContent)
  return (
  <Layout>
    <SEO title="Race Information" />
    <PageTitle 
      title="Race Information"
    />
    <Container fluid className="my-4">
      {/* <CovidAlert /> */}
      {pageContent.map((edge, index) => {
        console.log(edge)
        return (
          <>
            <Row className="my-4">
              <Col>
                <Row>
                  <Col>
                  {edge.sectionImage && (
                    <div className="float-right centerOnSmall">
                    <Img fluid={edge.sectionImage.fluid} alt={edge.sectionTitle} className="rounded" style={{ minHeight: '300px' }}/>
                  </div>
                  )}
                    <h2>{edge.sectionTitle}</h2>
                    {documentToReactComponents(edge.sectionContent.json, options)}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
          </>
        )
      })
      }

      {/* <Row className="my-4">
        <Col>
          <Row>
            <Col>
            <div className="float-right centerOnSmall">
              <Img fluid={data.raceStart.childImageSharp.fluid} alt="Teton Ridge Classic Race Start, 2012" className="rounded" />
            </div>
            <h3>The 21st Annual TRC</h3>
            <p>For the last 2 decades the Teton Ridge Classic (TRC) has been one of the premier classic events in the region. This year we are racing a new venue that offers incredible classic skiing that is reminiscent of the original venue. Like the original race we are returning to our roots and linking the event to a local ranch where people can stay and we can all gather post-race to swap stories, refuel, and hand out awards.</p>
            <p>
            We are currently working with 6 different stakeholders/landowner groups to make this event happen and we want to be fully transparent that this is a huge undertaking. In addition to the amazing purpose-built nordic trails that the course will be on, we will be adding a few new sections of trail that have never been groomed before. This includes a highway underpass, a rolling bike path, and even an uphill classic single-track section through the woods. We skied the loop a ton last year and frankly the potential is amazing. That said, there are some unknowns on how the entire course will groom out and we still have some work to do with the landowners and government agencies. As such, for year one, we are doing a low-key test race where we will be looking for feedback from the nordic racing community. This year’s TRC will have approximately a 16 km and 8 km course and a juniors 2 km course. Future years we hope to bring back a 25 km distance. 
            </p>
            <h3>Time &amp; Place</h3>
            <p>Saturday, January 06, 2024 at Mike Harris Trail System, Victor, Idaho.</p>
            
            <h3>Race Bib Pick Up</h3>
            <p>9:00 am - 11:00 am race morning. Location: Mike Harris Campground and Trail System. Map for participant parking will be posted in December. </p>
            <h3>Start Times</h3>
            <p>
            <strong>16 km</strong> - 10:30 a.m.<br />
            <strong>8 km</strong> - 10:40 a.m.<br />
            <strong>2 km</strong> - 11:30 a.m.
            </p>
            
           
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
            <h2>Course &amp; Distance</h2>
            <div className="float-right centerOnSmall">
               <Img fluid={data.courseDistance1.childImageSharp.fluid} alt="Teton Ridge Classic Race Start, 2012" className="rounded" />
            </div>
            
            <p>Course description and maps will be posted in late December.</p>
            
            <hr />
            <h2>Registration</h2>
            <p>Registration is only available online.</p>
            <div className="my-4">
            <a href="https://www.webscorer.com/register?raceid=330930" target="_blank" rel="noopener noreferrer"><Button className="trcButton">Click Here To Register {" "}<span><FontAwesomeIcon icon={faAngleRight}/></span></Button></a></div>
            <Row>
              <Col sm={6}>
              <p><strong>By December 15, 2023</strong><br />
            U20 and younger, any race: <strong>$15</strong><br />
            8 km, 16 km: <strong>$30</strong><br />
            Touring Class: <strong>$30</strong>
            </p>
              </Col>
              <Col sm={6}>
              <p><strong>After December 15, 2023</strong><br />
              U20 and younger, any race: <strong>$20</strong><br />
              8 km, 15 km: <strong>$40</strong><br />
              Touring Class: <strong>$40</strong>
              </p>
            </Col>
            </Row>          
            <p>Online Registration closes at 5 pm on Friday before the race. There is no race day registration or phone registration.</p>

            <hr />
            <h2>Age Classes</h2>
            <p><strong>16 km</strong> race will have 10 year age group classes (except for the youngest): 10-14, 15-19, 20-29, 30-39, 40-49...</p>
            <p><strong>8 km</strong> race will have 2 year age groups for juniors and 10 year age groups for older age groups: U10, U12, U14, U16, U20, 20-59, 60-69, 70-79, 80-89, 90+</p>
            <p><strong>4 km</strong> race will have 2-year age groups: U20, U16, U14, U12, U10</p>
            <p><strong>Touring Class</strong> Please begin your tour after the start of the 8 km race but give the 8 km skiers at least a 5-minute head start and finish by 12:30 p.m.</p>
            <p><strong>Race Bibs must be returned immediately after the race.</strong> If you are picking up a race goody bag for someone who registered but was unable to attend, please leave the race bib with the organizers.</p>
            <hr />
            <h2>Lunch &amp; Awards</h2>
            <p>We will be serving soup and bread post-race at the Moose Creek Ranch, and we will announce top 3 finishers in each race. We will not be giving out medals this year.</p>
            <h2>Lodging</h2>
              <p><a href="https://www.moosecreekranch.com/" target="_blank" rel="noopener noreferrer">Moose Creek Ranch</a> is offering 15% off with the promotion code "tetonridgeclassic". Moose Creek Ranch is within skiing distance of the start and finish of the race. Moose Creek Ranch will also be the future start and finish of the TRC when our final course will be lined out in 2025.</p>
            <hr />
            <h3>Teton Valley Trails &amp; Pathways "Linking People and Places"</h3>
            <p>TVTAP is a 501(c)(3) nonprofit organization that develops and maintains year-round non-motorized transportation and recreation facilities in Teton Valley. The organization brings together citizens, businesses, and local governments to expand the valley's opportunities for outdoor recreation and nature appreciation, while linking communities, providing access to national forest trails, and promoting healthy mountain lifestyles. TVTAP grooms over 35 kms of nordic trails in Teton Valley. Money raised from Teton Ridge Classic is used towards fulfilling our goals as an organization. For more information, visit <a href="https://tvtap.org" target="_blank" rel="noopener noreferrer">www.tvtap.org</a></p>
            <h3>The Teton Valley Ski Education Foundation (TVSEF)</h3>
            <p>TVSEF has been the non-profit ski team in Teton Valley for over 30 years. Our mission is to provide the children of Teton Valley an affordable winter sports program that offers skill improvement and leadership training, builds self-confidence, and creates within each athlete a lifelong love of winter sports. visit<a href="https://tvsef.org" target="_blank" rel="noopener noreferrer"> www.tvsef.org</a></p>
            <hr />
            <h3>Streubel Legacy Scholarship</h3>
            <p>This scholarship was created in honor of very dedicated and long-time nordic advocates in Teton Valley, Dan and Melisa Streubel. They both have given so much to cultivate the cross country community by running races, coaching kids, donating countless hours of their time, and in general sparking a love of cross country.</p>
            <p>This scholarship will be awarded to one athlete on the Teton Valley Ski Education Foundation (TVSEF) cross country team each year based on merit and input from coaches. The funds will cover competition and registration fees. As this fund grows, TVSEF hopes the award may also serve as a gear stipend for athletes to purchase new skis, poles, boots, etc. to help them achieve their competition goals.</p>
            <p>If you would like to donate, please visit <a href="https://www.tvsef.org/donate.html" target="_blank" rel="noopener noreferrer">www.tvsef.org</a> Thank you for your support.</p>
            <hr />
            <h3>Contact Information</h3>
            <p><strong>Dave Bergart</strong> &mdash; Race Director<br />
            </p>
            <div className="my-4">
            <Link to="/contact"><Button className="trcButton">Send Us A Message{" "}<span><FontAwesomeIcon icon={faAngleRight}/></span></Button></Link></div>
            
            </Col>
          </Row>

        </Col> 
        </Row> */}

        <Container fluid>
        <Row>
          <Col>
          <h3>Contact Information</h3>
            <p><strong>Dave Bergart</strong> &mdash; Race Director<br />
            </p>
            <div className="my-4">
            <Link to="/contact"><Button className="trcButton">Send Us A Message{" "}<span><FontAwesomeIcon icon={faAngleRight}/></span></Button></Link></div>
          </Col>
        </Row>
        <hr />
        <Row>
              <Partners />
            </Row>
            <hr />
            <Row>
              <Sponsors />
            </Row>
    </Container>

    </Container>
  </Layout>
)
}

export const query = graphql`
  {
    allContentfulInfoPage(filter: {slug: {eq: "info"}}) {
      edges {
        node {
          pageSection {
            sectionTitle
            sectionImage {
              fluid {
                srcSetWebp
              }
            }
            sectionContent {
              json
            }
          }
        }
      }
    }
  }
`

// export const query = graphql`
//   query {
//     raceStart: file(relativePath: { eq: "Ken-Levy-photo-of-Start-2012.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     courseDistance1: file(relativePath: { eq: "course-distance-1.jpg" }) {
//       childImageSharp {
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `

export default IndexPage
